import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'
import { FaMountain } from "react-icons/fa";

const About = () => {
    return (
        <section id='about'>
            <h5>Get To Know</h5>
            <h2>About Me</h2>

            <div className='container about__container'>
                <div className='about__me'>
                    <div className='about__me-image'>
                        <img src={ME} alt='About Me' />
                    </div>
                </div>

                <div className='about__content'>
                    <div className='about__cards'>
                        <article className='about__card'>
                            <FaAward className='about__icon'/>
                            <h5>Experience</h5>
                            <small>1+ Years Working</small>
                        </article>

                        <article className='about__card'>
                            <VscFolderLibrary className='about__icon'/>
                            <h5>Projects</h5>
                            <small>Check out my cool projects below!</small>
                        </article>

                        <article className='about__card'>
                            <FaMountain className='about__icon'/>
                            <h5>Favorite Spots</h5>
                            <small>Hawk Hill</small>
                            <br/>
                            <small>Pebble Beach</small>
                        </article>
                    </div>

                    <p>
                    Hi! My name is Akio and I'm a Full-Stack Engineer at Deloitte based in San Jose, CA.
                    I graduated from Purdue University with a Bachelor's in Computer Engineering.
                    In my free time, I enjoy bouldering, golfing, playing NYT/LinkedIn Games, and exploring the beautiful California nature.
                    I'm always excited to research the latest technologies and strive to create innovative solutions that make a positive impact.
                    I look forward to connecting with fellow professionals, sharing my experiences, and exploring new opportunities in the software engineering industry.
                    Feel free to explore my website further to learn more about my skills, projects, and achievements!
                    </p>

                    <div className='about-btn-container'>
                        <a href="#contact" className='btn btn-primary'>Let's Chat</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
